import {Fecha,Nombre,Gestion,Numero,Seleccion} from "./inputs/Datos"
import Form from 'react-bootstrap/Form';


function TablaDatos({usuario, cambiarUsuario, fecha, cambiarFecha, motivo, cambiarMotivo, lugarEmisionCI, cambiarlugarEmisionCI}) {
    return (
        <div className='container col-10'>
            <div className='row justify-content-end'>
                <div className="col-7">
                    <Nombre
                        usuario={usuario}
                        cambiarUsuario={cambiarUsuario}
                    />
                </div>
                <div className="col-5">
                    <Fecha
                        fecha={fecha}
                        cambiarFecha={cambiarFecha}
                    />
                </div>
            </div>
            <div className='row justify-content-end'>
                <div className="col-7">
                    <Numero
                        usuario={usuario}
                        cambiarUsuario={cambiarUsuario}
                    />                   
                </div>
                <div className="col-5">
                    <div className="row align-items-center p-1">
                        <div className="col-5">
                            <Form.Label style={{ height: '25px' }}>Expedido en: </Form.Label>
                        </div>
                        <div className="col-7" >
                            <select required className="form-select form-select-sm ba" onChange={(e)=>{cambiarlugarEmisionCI(e.currentTarget.value)}} aria-label=".form-select-sm example" style={{ background:'#d9d9d9'}}>
                                {
                                    lugarEmisionCI?
                                    <option defaultValue>{lugarEmisionCI}</option>
                                    :
                                    <option defaultValue>COCHABAMBA</option>
                                }
                                <option value="BENI">BENI</option>
                                <option value="CHUQUISACA">CHUQUISACA</option>
                                <option value="COCHABAMBA">COCHABAMBA</option>
                                <option value="LA PAZ">LA PAZ</option>
                                <option value="ORURO">ORURO</option>
                                <option value="PANDO">PANDO</option>
                                <option value="POTOSI">POTOSI</option>
                                <option value="SANTA CRUZ">SANTA CRUZ</option>
                                <option value="TARIJA">TARIJA</option>
                                <option value="OTRO">OTRO</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>    
            <div className='row justify-content-end'>
                <div className="col-7">
                    <Seleccion
                        fecha = {fecha}
                        cambiarFecha = {cambiarFecha}
                    />
                </div>
                <div className="col-5">
                    <Gestion
                        fecha = {fecha}
                        cambiarFecha = {cambiarFecha}
                    />
                </div>
            </div>
            <div className='container col p-0'>
                <div className='row align-items-center p-1'>
                    <div className="col-3">
                        <Form.Label style={{ height: '25px' }}>Motivo de declaración: </Form.Label>
                    </div>
                    <div className="col-9" >
                        <Form.Control required maxLength={50} style={{ height: '25px', background: '#D9D9D9' }} type="text" defaultValue={motivo} onChange={(e)=>{cambiarMotivo(e.currentTarget.value)}}/>
                    </div>
                </div>
            </div>
      </div>
    );
  }
  export default TablaDatos;