import { useEffect, useState } from "react";
import DeclaracionJuradaServices from "../services/declaracionJuradaServices";
import Boton from "../components/Boton";
import { Col, Container, Form, Row } from "react-bootstrap";
import TablaDeclaracion from "../components/TablaDeclaracion";
import AuthService from "../services/authService";
import TarjetaUsuario from "../components/TarjetaUsuario";
import { useNavigate } from "react-router-dom";
function DocentePrincipal() {  

    const navigate = useNavigate()
    const [usuario, setUsuario] = useState(null)
    const [listaGestiones, setListaGestiones] = useState([])
    const [listaDeclaraciones, setListaDeclaraciones] = useState([])
    const [gestion, setGestion] = useState(null)

    const loadListaDeclaraciones = async (id) => {
        const response = await DeclaracionJuradaServices.InformacionUsuario(id)
        if (response.data.success === true) {
            setUsuario(response.data.data)
            const responseGestiones = await DeclaracionJuradaServices.getListaGestiones(id)
            if (responseGestiones.data.success === true) {
                const gestiones = responseGestiones.data.data
                if(gestiones.length > 0) {
                    setGestion(gestiones[0].gestion)
                    setListaGestiones(gestiones)
                }
            } else {
                console.log("error ", responseGestiones)
            }
        } else {
            console.log("error ", response)
        }
    }

    useEffect(() => {
        let id = AuthService.getIdUsuario()
        loadListaDeclaraciones(id)
    }, [])

    
    const crearDeclaracion = () => {
        navigate('/formulario/0')
    }
    
    const cargarListaDeclaraciones = async () => {
        let id = AuthService.getIdUsuario()
        if(gestion && usuario){
            const responseDeclaraciones = await DeclaracionJuradaServices.getListaDeclaracionesGestion(id, gestion)
            if (responseDeclaraciones.status === 200 && responseDeclaraciones.data.success === true) {
                setListaDeclaraciones(responseDeclaraciones.data.data)
            } else {
                console.log("error ", responseDeclaraciones)
            }
        }
    }
    
    useEffect(() => {  
            cargarListaDeclaraciones()         
    }, [gestion])    

    return (
        <Container style={{ width: '50em' }}>
            <br />
            {usuario ? <Row>
                <TarjetaUsuario nombre1={usuario.nombre1} nombre2={usuario.nombre2} paterno={usuario.paterno} materno={usuario.materno} email={usuario.email} ci={usuario.ci}></TarjetaUsuario>
            </Row> : null}
            <br />
            <Row>
                <Col style={{ textAlign: "center" }}>
                    <Boton nombre='Nueva Declaración' manejarClick={crearDeclaracion} name='Nueva Declaración'></Boton>
                </Col>
                <Col>
                    <Row style={{ justifyContent: "center" }}>
                        <Form style={{ width: '20em' }}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={3}>Gestión:</Form.Label>
                                <Col>
                                    <Form.Select onChange={(e) => setGestion(e.target.value)} defaultValue={gestion}>
                                        {
                                            listaGestiones.map((gestion, index) => (
                                                <option value={gestion.gestion} key={index}>{gestion.gestion}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Row>
                </Col>
            </Row>
            <br />
            <Row className="justify-content-center">
                <TablaDeclaracion listaDeclaraciones={listaDeclaraciones} cargarListaDeclaraciones={cargarListaDeclaraciones} />
            </Row>
        </Container>
    )
}

export default DocentePrincipal;