import React from 'react'

const Footer = () => {
    const currentYear  = new Date().getFullYear();
    return (
        <>
            <nav className="navbar justify-content-center" style={{ backgroundColor: 'black', color: 'white' }}>
                <div>
                    <p href="#" className="Navbar-a">DERECHOS RESERVADOS @ {currentYear} | DTIC UMSS</p>
                </div>
            </nav>
        </>
    )
}

export default Footer