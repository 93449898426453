import Form from 'react-bootstrap/Form';
import TablaCategoria from "./TablaCategoria";
import TablaDatos from "./TablaDatos";
import Boton from "./Boton";
import TablaInstitucionPublica from "./TablaInstitucionPublica";
import TablaRentaJubilacion from "./TablaRentaJubilacion";
import { useState, useEffect } from "react";
import AuthService from '../services/authService';
import { useParams, useNavigate } from "react-router-dom";
import DeclaracionJuradaServices from '../services/declaracionJuradaServices';
import BotonRojo from './BotonRojo';
import { Confirmation, ToastError, ToastInfo } from './Alerts';

function Formulario() {
    const navigate = useNavigate()
    let contadorJubilacion = 0

    const [usuario, setUsuario] = useState({})//Carga los datos del usuario
    const [fecha, setFecha] = useState({})//Carga la fecha de la declaracion asociada al usuario
    const [categoria, setCategoria] = useState([])//Carga las categorias de la tabla usuario_categoria asociadas al usuario
    const [categorias, setCategorias] = useState([])//Carga la lista de las categorias que existen registradas en la base de datos (tabla categoria)
    const [instituciones, setInstituciones] = useState([])//Carga las instituciones de la tabla usuario_instituciones asociadas al usuario
    const [listaInstituciones, setListaInstituciones] = useState([])//Carga la lista de las instituciones que existen registradas en la base de datos (tabla institucion)
    const [motivo, setMotivo] = useState('')
    const [lugarEmisionCI, setLugarEmisionCI] = useState('COCHABAMBA');
    const { idDeclaracion } = useParams()
    const [clickedButton, setClickedButton] = useState(null);

    const loadDeclaracion = async (idDeclaracion) => {
        const response = await DeclaracionJuradaServices.getDeclaracion(idDeclaracion)
        if (response.data.success === true) {
            setUsuario(response.data.usuario)
            setFecha(response.data.fecha)
            setCategoria(response.data.categoria)
            setCategorias(response.data.categorias)
            setInstituciones(response.data.instituciones)
            setListaInstituciones(response.data.listaInstituciones)
            setMotivo(response.data.fecha.motivo)
            setLugarEmisionCI(response.data.fecha.lugar_emision_ci)
        } else {
            console.log('error', response)
        }
    }

    const cargarUltimaDeclaracion = async () => {
        const mes = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE']
        const hoy = new Date()
        const response = await DeclaracionJuradaServices.getUltimaDeclaracion()
        if (response.data.success === true) {
            setUsuario(response.data.usuario)
            setFecha({
                fecha: hoy.toISOString().split('T')[0],
                mes: mes[hoy.getMonth()],
                gestion: hoy.getFullYear().toString(),
                estado: "PEN"
            })
            setCategoria(response.data.categoria)
            setCategorias(response.data.categorias)
            setInstituciones(response.data.instituciones)
            setListaInstituciones(response.data.listaInstituciones)
        } else {
            console.log('error', response)
            generarDatos()
        }
    }

    /**
     * Permite generar la informacion para generar las tablas del formulario en caso de que se este creando una nueva declaracion
     */
    const generarDatos = async () => {
        const mes = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE']
        const hoy = new Date()
        setFecha({
            fecha: hoy.toISOString().split('T')[0],
            mes: mes[hoy.getMonth()],
            gestion: hoy.getFullYear().toString(),
            estado: "PEN"
        })
        const idUsuario = AuthService.getIdUsuario()
        let response = await DeclaracionJuradaServices.InformacionUsuario(idUsuario)
        if (response.data.success === true) {
            console.log(response.data.data)
            setUsuario(response.data.data)
            response = await DeclaracionJuradaServices.getCategorias()
            if (response.data.success === true) {
                setCategorias(response.data.categorias)
                generarTablaRemuneracion(response.data.categorias)
                response = await DeclaracionJuradaServices.getInstituciones()
                if (response.data.success === true) {
                    setListaInstituciones(response.data.instituciones)
                } else {
                    console.log('error', response)
                }
            } else {
                console.log('error', response)
            }
        } else {
            console.log('error', response)
        }
    }

    const generarTablaRemuneracion = (categorias) => {
        let categoria = []
        categorias.forEach(cat => {
            categoria.push({
                id_categoria: cat.id_categoria,
                remuneracion: 0
            })
        });
        setCategoria(categoria)
    }

    const editarDeclaracion = async () => {

        let trimedInstitutions = instituciones

        trimedInstitutions.forEach(institucion => {
            institucion.cargo = institucion.cargo.trim();
            institucion.nombre = institucion.nombre.trim();
        });

        const declaracion = { "fecha": fecha, "motivo": motivo.trim(), "lugar_emision_ci":lugarEmisionCI, "categoria": categoria, "instituciones": trimedInstitutions }
        const response = await DeclaracionJuradaServices.editDeclaracion(idDeclaracion, declaracion)
        if (response.data.success === true) {            
            ToastInfo.fire({title: response.data.message});
            navigate('/declaraciones?message=' + response.data.message, { replace: true })
        } else {
            ToastError.fire({title: response.data.message })
        }
    }


    useEffect(() => {
        if (idDeclaracion === '0') {
            cargarUltimaDeclaracion()
        } else {
            loadDeclaracion(idDeclaracion)
        }
    },[])

    //boton "añadir institución"
    function onSubmitI() {
        let instPub = Array.from(instituciones)
        instPub.push({ id: null, nombre: "", cargo: "", total_ganado: 0, tipo: "I" })
        setInstituciones(instPub)
    }

    //boton "añadir jubilación"
    function onSubmitR() {
        if (contadorJubilacion === 1) {
            ToastInfo.fire({title: "Solo se puede tener una renta de jubilación."})
        } else {
            let instPub = Array.from(instituciones)
            instPub.push({ id: null, nombre: "", cargo: "", total_ganado: 0, tipo: "R" })
            setInstituciones(instPub)
        }
    }

    //boton crear nueva declaracion
    const crearDeclaracion = async () => {

        let trimedInstitutions = instituciones

        trimedInstitutions.forEach(institucion => {
            institucion.cargo = institucion.cargo.trim();
            institucion.nombre = institucion.nombre.trim();
        });

        const id = AuthService.getIdUsuario()
        const nuevaDeclaracion = {
            'informacion': fecha,
            'motivo' : motivo.trim(),
            'lugar_emision_ci' : lugarEmisionCI,
            'categorias': categoria,
            'instituciones': trimedInstitutions
        }
        const response = await DeclaracionJuradaServices.createDeclaracion(id, nuevaDeclaracion)
        if (response.data.success === true) {
            ToastInfo.fire({title: response.data.message});
            navigate('/declaraciones', { replace: true })
        } else {
            ToastError.fire({title: response.data.message })
        }
    }

    //funcion para marcar un declaracion jurada con declarada (DEC)
    const declarar = async () => {
        if(!(await Confirmation.fire()).isConfirmed){
            return;
        }

        let trimedInstitutions = instituciones

        trimedInstitutions.forEach(institucion => {
            institucion.cargo = institucion.cargo.trim();
            institucion.nombre = institucion.nombre.trim();
        });

        let response
        if (idDeclaracion === '0') {
            const id = AuthService.getIdUsuario()                                                                                        
            response = await DeclaracionJuradaServices.createDeclaracion(id, { "informacion": { ...fecha, estado: 'DEC' }, "motivo": motivo.trim(), "lugar_emision_ci":lugarEmisionCI, "categorias": categoria, "instituciones": trimedInstitutions })
        } else {
            response = await DeclaracionJuradaServices.editDeclaracion(idDeclaracion, { "fecha": { ...fecha, estado: 'DEC' }, "motivo": motivo.trim(), "lugar_emision_ci":lugarEmisionCI, "categoria": categoria, "instituciones": trimedInstitutions })
        }
        if (response.data.success === true) {
            ToastInfo.fire({title: response.data.message});
            navigate('/declaraciones', { replace: true })
        } else {
            ToastError.fire({title: response.data.message });
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        if(clickedButton === 'save'){
            if(idDeclaracion === '0') 
                crearDeclaracion();
            else 
                editarDeclaracion();
        }

        if(clickedButton === 'declare'){
            declarar();
        } 
    }    

    return (
        <Form onSubmit={handleSubmit}>
            <div className='container' style={{ width: '60em' }}>
                <div className="row justify-content-center">
                    <div className='row justify-content-center'>
                        <div className="col-12 color_text text-center mt-3">
                            <h2>FORMULARIO DE DECLARACIÓN JURADA</h2>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                            <TablaDatos
                                usuario={usuario}
                                cambiarUsuario={setUsuario}
                                fecha={fecha}
                                cambiarFecha={setFecha}
                                motivo={motivo}
                                cambiarMotivo={setMotivo}
                                lugarEmisionCI={lugarEmisionCI}
                                cambiarlugarEmisionCI={setLugarEmisionCI}
                            />
                    </div>
                    <div className='row justify-content-center'>
                        <div className="col-12">
                            {
                                categoria && categorias ?
                                    <TablaCategoria
                                        categoria={categoria}
                                        cambiarCategoria={setCategoria}
                                        categorias={categorias}
                                        idUsuario={usuario.id_usuario}
                                    />
                                    : null
                            }
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <Boton
                            nombre="Añadir institución"
                            manejarClick={onSubmitI}
                            name="I"
                        />

                        <Boton
                            nombre="Añadir jubilación"
                            manejarClick={onSubmitR}
                            name="R"
                        />
                    </div>

                    <div className="row justify-content-center" >
                        {
                            instituciones.filter(institucion => institucion.tipo === "I").map((institucion, index) => (
                                <TablaInstitucionPublica
                                    instituciones={instituciones}
                                    cambiarInstituciones={setInstituciones}
                                    listaInstituciones={listaInstituciones}
                                    cambiarListaInstituciones={setListaInstituciones}
                                    indice={instituciones.indexOf(institucion)}
                                    index={index}
                                    key={"inst" + index}
                                />
                            ))
                        }
                    </div>
                    <div className="row justify-content-center">
                        {
                            instituciones ?
                                instituciones.filter(institucion => institucion.tipo === "R").map(institucion => (
                                    contadorJubilacion++ !== 1 ?
                                        <TablaRentaJubilacion
                                            instituciones={instituciones}
                                            cambiarInstituciones={setInstituciones}
                                            listaInstituciones={listaInstituciones}
                                            cambiarListaInstituciones={setListaInstituciones}
                                            indice={instituciones.indexOf(institucion)}
                                            longitud={instituciones.length}
                                            key={institucion.id}
                                        />
                                        : null
                                ))
                                : null
                        }
                    </div>
                    {
                        instituciones ?
                            <div className="row justify-content-center">
                                <Form.Text id="passwordHelpBlock" muted>
                                'NOTA.- El monto declarado corresponde al total ganado (no al líquido pagable), es decir al monto percibido
                                antes de deduciones, aportes laborales e impuestos. Se considera institución pública a toda aquella que se
                                encuentra comprendida en los Artículos 3, 4 y 5 de la Ley 1178 del 20 de Julio de 1990.
                                </Form.Text>
                            </div>
                            : null
                    }
                    <div className="row justify-content-center">
                        <BotonRojo
                            nombre={"Cancelar"}
                            manejarClick={() => navigate('/declaraciones', { replace: true })}
                        />
                        <button onClick={()=>setClickedButton('save')} name="Guardar" type="submit" className='cabecera_background m-2 text-white' style={{ width: '190px', borderRadius: '8px' }}>
                            <p className='m-0'>Guardar</p>
                        </button>

                        <button onClick={()=>setClickedButton('declare')} name="Declarar" type="submit" className='cabecera_background m-2 text-white' style={{ width: '190px', borderRadius: '8px' }}>
                            <p className='m-0'>Declarar</p>
                        </button>                       
                    </div>
                </div>
            </div>
        </Form>
    );
}
export default Formulario;