import React from 'react'
import Button from 'react-bootstrap/Button';
import { useLocation } from 'react-router';
import AuthService from '../services/authService';
import { NavLink, useNavigate } from 'react-router-dom';
import LogoUMSS from './assets/img/logoUMSS.png'
import { getCookie } from '../services/cookies';

const Navbar = () => {

    const navigate = useNavigate()
    const location = useLocation();

    const cerrarSesion = () =>{
        let aux = getCookie('websis_redirect');
        AuthService.cerrarSesion();
        if(aux){
            window.location.href = 'https://websis.umss.edu.bo/serv_docentes.asp';
        } else {
            navigate('/');
        }
    }

    return(
        <>
            <nav className="navbar" style={{backgroundColor:'#003377', color: 'white'}}>
                <div className="container-fluid">
                    <NavLink to="/">
                        <img src= {LogoUMSS} alt="Logo"className="d-inline-block align-text-top" height='80'/>  
                    </NavLink>
                    <div className="col">
                            <h2 className="Navbar-a">Personal Académico - UMSS</h2>
                            <h4 className='Navbar-a'>Declaración Jurada</h4>
                        </div>
                    {
                        location.pathname === "/index" || location.pathname === "/login" ?
                        <div className='d-flex'>
                            <NavLink to="/login">
                                <Button id='login' variant="light">Iniciar Sesión</Button>{' '}
                            </NavLink>
                        </div>: 
                        <div className='d-flex'>                          
                            <Button id='logon' variant="light" onClick={cerrarSesion}>Cerrar Sesión</Button>{' '}
                        </div>
                    }
                </div>
            </nav>     
       </>
        )
}
export default Navbar