
import { Card, Col, Row } from "react-bootstrap";

function TarjetaUsuario({ nombre1, nombre2, paterno, materno, email, ci }) {

    return (
        <>
            <Card style={{ borderColor: '#003377', color: '#003377' }}>
                <Card.Body>
                    <Row>
                        <Col style={{ textAlign: "right" }}>
                            <strong>Nombre: </strong>
                        </Col>
                        <Col>
                            {`${nombre1} ${nombre2} ${paterno} ${materno}`}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "right" }}>
                            <strong>Correo: </strong>
                        </Col>
                        <Col>
                            {email}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "right" }}>
                            <strong>C.I. o Pasaporte: </strong>
                        </Col>
                        <Col>
                            {ci}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export default TarjetaUsuario;