import { API } from './connection'
import { deleteCookie, getCookie } from './cookies';
/**Servicios para la autenticacion del usuario */
const AuthService = {
    /**
     * Servicio para iniciar sesion, envia una solicitud con las credenciales del usuario a la API
     * este deveulve la respuesta de la api como un objeto
     * @param {*} credenciales 
     * @returns Object
     */
    iniciarSesion: async ( credenciales = {}) => {    
        let response = null;
        await API.post("/usuario/iniciar-sesion", credenciales)
            .then((res) => { response = res})
            .catch((err) => { response = err.response ? err.response : {}})
        return response
    },

    iniciarSesionWebsis: async(session_id) => {
        let response = null;        
        await API.post("/usuario/authenticate-user-websis", {session_ddjj: session_id})
            .then((res) => { response = res})
            .catch((err) => { response = err.response ? err.response : {}})
        return response
    },

    /**
     * Servicio para cerrar sesion, elimina los datos del usuario de las cookies
     */
    cerrarSesion: () => {
        deleteCookie("id_usuario")
        deleteCookie("token")
        deleteCookie("rol")
        deleteCookie("websis_redirect")

    },

    /**
     * Servicio que retorna el token del usuario que se encuentra almacenado en las cookies
     * @returns string
     */
    getToken: () => {        
        return getCookie("token")
    },

    /**
     * Servicio que retorna el id del usario que se encuentra almacenado en las cookies
     * @returns string
     */
    getIdUsuario: () => {
        return getCookie("id_usuario")
    },

    /**
     * Servicio que retorna los roles del usuario almacenados en las cookies
     * @returns array
     */
    getRolUsuario: () => {
        return getCookie("rol")
    },
}

export default AuthService;