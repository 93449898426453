import Swal from 'sweetalert2'

const timerDuration = 5000

export const ToastSuccess = Swal.mixin({
    toast: true,
    icon: "success",
    position: "top-end",
    showConfirmButton: false,
    timer: timerDuration,
    timerProgressBar: true,
    showCloseButton: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
});

export const ToastError = Swal.mixin({
    toast: true,
    icon: "error",
    position: "top-end",
    showConfirmButton: false,
    timer: timerDuration,
    timerProgressBar: true,
    showCloseButton: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
});

export const ToastInfo = Swal.mixin({
    toast: true,
    icon: "info",
    position: "top-end",
    showConfirmButton: false,
    timer: timerDuration,
    timerProgressBar: true,
    showCloseButton: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
});

export const Confirmation = Swal.mixin({
        title: "¿Está seguro?",
        text: "Esta acción no se puede deshacer.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        confirmButtonColor:'#003377',
        cancelButtonText: "Cancelar",     
});

export const ErrorBig = Swal.mixin({
    title: "Error",
    icon: "error",    
    confirmButtonText: "Ok",
    confirmButtonColor:'#003377', 
});

/********HOW TO INVOKE********/

// Toast.fire({
//     title: "Signed in successfully"
// });

// ErrorBig.fire({
//     text: "Esta acción no se puede deshacer."