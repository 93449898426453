import Categoria from "./inputs/Categoria";
import Total from "./inputs/Total";
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from "react";

function TablaCategoria({categoria, cambiarCategoria,categorias,idUsuario}) {
  const [total, setTotal] = useState(0)

  //El metodo nos devuelve la suma de las remuneraciones de todas las categorias asociadas al usuario
  function sumar(){   
    let suma = 0
    for(var valor of categoria){
      suma += parseFloat(valor.remuneracion)
    }
    setTotal(suma.toFixed(2))
  }

  useEffect(()=>{
    if(Object.values(categoria).length > 0){
      sumar()
    }
}, [categoria])

    return (
      <>
        <div className='row justify-content-center'>
            <div className="col-12 color_text">
              <h3>A. Universidad Mayor de San Simón</h3>
            </div>
            <div className="col-10">
                  <div className='row cabecera_background p-2 text-white'>
                    <div className="col-8">
                        <Form.Label>Categoría</Form.Label>
                    </div>
                    <div className="col-4">
                        <Form.Label>Remuneración (Bs.)</Form.Label>
                    </div>
                  </div>

                <div className="col">
                    {
                      categorias.map( (cat, index) => (
                        <Categoria
                          label = {(index+1) + ') ' + cat.nombre}
                          categoria = {categoria}
                          cambiarCategoria={cambiarCategoria}
                          name = {cat.id_categoria}
                          key={cat.nombre}
                          id_usuario={idUsuario}
                        />
                      ))
                    }
                    {
                      <Total
                        label = {"Total Ganado"}
                        suma = {total}
                      /> 
                    }
                </div>
            </div>
            <div className="col-12">
                <Form.Text id="passwordHelpBlock" muted>
                IMPORTANTE: En el caso de LOS ITEMS 1 y 2 (docentes y administrativos) se considera el total ganado mensual 
                independientemente del tipo de contrato (indefinido, plazo fijo o servicios). En el caso de los servicios 
                comprendidos del ITEM 3 al ITEM 9 se considera la remuneración de las fechas que se ha efectuado el servicio
                sin considerar si este ya ha sido pagado en efectivo o no. No debe incluirse el servicio para el cual se firma
                la presente declaración. 
                </Form.Text>
            </div>
        </div>
      </>
    );
  }
  export default TablaCategoria;