import '../styles/categoria.css'

function BotonRojo({ nombre, manejarClick }) {
    return (
        <button className='color_rojo m-2 text-white' style={{ width: '190px', borderRadius: '8px' }}
            onClick={manejarClick}
        >
            <p className='m-0'>{nombre}</p>
        </button>

    );
}
export default BotonRojo;