import '../styles/categoria.css'

function Boton({ nombre, manejarClick, name}) {
    return (

        <button type="button" name={name} className='cabecera_background m-2 text-white' style={{ width: '190px', borderRadius: '8px' }}
            onClick={manejarClick}
        >
            <p className='m-0'>{nombre}</p>
        </button>
    );
}
export default Boton;                                                               