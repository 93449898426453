import { Table } from "react-bootstrap"
import { useEffect } from "react"
import { EyeFill, PencilSquare, PrinterFill, TrashFill } from "react-bootstrap-icons"
import DeclaracionJuradaServices from "../services/declaracionJuradaServices"
import { useNavigate } from "react-router-dom";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { formatoPDF } from "../reports/formatoPDF";
import AuthService from "../services/authService";
import { Confirmation, ToastInfo } from "./Alerts";

function TablaDeclaracion({listaDeclaraciones, cargarListaDeclaraciones, idUsuario, gestion}) {
    const navigate = useNavigate()
    const rolUsuario = AuthService.getRolUsuario()
    /**
     * permite obtener los datos de la declaracion jurada y genera el documento pdf
     * @param {integer} idDeclaracion identificador de la declaracion jurada
     */
    const generarPDF = async(idDeclaracion) => {
        const response = await DeclaracionJuradaServices.getDeclaracion(idDeclaracion)
        if(response.status === 200 && response.data.success === true){
            pdfMake.vfs = {
                ...pdfFonts.pdfMake.vfs,
            };
            pdfMake.fonts = {
                // Default font should still be available
                Roboto: {
                    normal: 'Roboto-Regular.ttf',
                    bold: 'Roboto-Medium.ttf',
                    italics: 'Roboto-Italic.ttf',
                    bolditalics: 'Roboto-Italic.ttf'
                },
            }
            const docDefinition = formatoPDF(
                response.data.fecha,
                response.data.usuario,
                response.data.categoria,
                response.data.categorias,
                response.data.instituciones)
            const generarPDF = pdfMake.createPdf(docDefinition);
            /*generarPDF.getBlob((blob) => {
                const url = URL.createObjectURL(blob);
            })*/
            generarPDF.open()
        }else{
            console.log('error', response)
        }
    }

    /**
     * elimina la declaracion jurada segun su identificador
     * @param {integer} idDeclaracion identificador de la declracion jurada
     */
    const eliminarDeclaracion = async(idDeclaracion) => {        
        if((await Confirmation.fire()).isConfirmed){
            const response = await DeclaracionJuradaServices.deleteDeclaracion(idDeclaracion)
            if(response.data.success === true){
                cargarListaDeclaraciones()
            }
            ToastInfo.fire({title: response.data.message})            
        }
    }

    useEffect(() => {
      cargarListaDeclaraciones();
    }, [idUsuario, gestion])
    

    
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th style={{minWidth: '150px'}}>Fecha de Llenado</th>
                    <th>Motivo de Declaración</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                {
                    listaDeclaraciones.map(declaracion => {                        
                        return (
                        <tr key={declaracion.id_declaracion_jurada}>
                            <td>{declaracion.fecha}</td>
                            <td>{declaracion.motivo}</td>
                            <td style={{textAlign:"center"}}>
                                { declaracion.estado === 'PEN' ?
                                    <PencilSquare color="#003770" onClick={() => navigate(`/formulario/${declaracion.id_declaracion_jurada}`)}></PencilSquare>:null
                                }
                                {  declaracion.estado === 'DEC' && rolUsuario === 'Administrador'?
                                    <EyeFill color="#003770" onClick={() => generarPDF(declaracion.id_declaracion_jurada)}></EyeFill> : null
                                }
                                { declaracion.estado === 'DEC' &&  rolUsuario === 'Docente' ?
                                    <PrinterFill color="#003770" onClick={() => generarPDF(declaracion.id_declaracion_jurada)}></PrinterFill> : null
                                }
                                { declaracion.estado === 'PEN' ? 
                                    <TrashFill color="#003770" onClick={() => eliminarDeclaracion(declaracion.id_declaracion_jurada)}></TrashFill> : null
                                }
                            </td>
                        </tr>
                    )})
                }
            </tbody>
        </Table>
    )
}

export default TablaDeclaracion