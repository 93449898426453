import { useEffect, useState } from "react"
import DeclaracionJuradaServices from "../services/declaracionJuradaServices"
import { Container, Row } from "react-bootstrap"
import TablaDeclaracion from "./TablaDeclaracion"


function ExpandoRow({idUsuario, gestion, className}) {
    const [listaDeclaraciones, setListaDeclaraciones] = useState([])

    const cargarDeclaraciones = async () => {
        const response = await DeclaracionJuradaServices.getListaDeclaracionesGestion(idUsuario, gestion)
        if (response.data.success === true) {
            setListaDeclaraciones(response.data.data)
        } else {
            setListaDeclaraciones([])
            console.log('error', response)
        }
    }

    useEffect(()=>{
        cargarDeclaraciones()
    }, [])

    return(
        <Container style={{width:'95%'}} className={className}>
            <Row style={{textAlign:"left"}}>
            <label>Declaraciones Juradas</label>
            </Row>
            <Row style={{justifyContent:"center", alignContent:"center"}}>
                <div style={{width:'85%'}}>
                    <TablaDeclaracion listaDeclaraciones={listaDeclaraciones} cargarListaDeclaraciones={cargarDeclaraciones} gestion={gestion} idUsuario={idUsuario}/>
                </div>
            </Row>
        </Container>
    )
}

export default ExpandoRow