import "../styles/spinner.css"
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import AuthService from "../services/authService";
import { setCookie } from "../services/cookies";

const LoginWebsis = () => {
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect( () => {
    AuthService.cerrarSesion();   
    if (params.data) {
      const getAccessData = async () => {         
        const response = await AuthService.iniciarSesionWebsis(params.data);

        if (response.data.success === true) {          
          setCookie('token', response.data.token, 7);
          setCookie('id_usuario', response.data.id_usuario, 7);
          setCookie('rol', response.data.rol, 7);
          setCookie('websis_redirect', true, 7);
          if(response.data.rol.Docente){
            navigate("/declaraciones")            
          }else{
            navigate("/declaracion-administracion")            
          }
        } else {
          navigate('/login');
        }
      }
      getAccessData().catch(console.error);
    } else {
      navigate("/index");
    }
  }, []); 

  return (
    <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
        <span className="loader"></span>
        <h2>Espere un momento mientras procesamos su solicitud por favor.</h2>
    </div>
  )
}

export default LoginWebsis