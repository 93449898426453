import AuthService from './authService';
import { API } from './connection'

const authHeader = () => {
    const token = AuthService.getToken();
    return { 'Authorization': `Bearer ${token}` }
}

const DeclaracionJuradaServices = {

    /**
     * obtiene la lista de gestiiones en la que el usuario a declarado
     * @param {integer} id identificador del usuario
     * @returns 
     */
    getListaGestiones: async (id, showOnlyDeclared = 0) => {
        let response = null
        await API.get('/declaracion-jurada/obtener-gestiones', { params: { id: id, onlyDec: showOnlyDeclared }, headers: authHeader() })
            .then((res) => { response = res })
            .catch((err) => { response = err.response ? err.response : {} })
        return response
    },

    /**
     * obtiene la lista de declaciones juradas realizadas por un usuario en una gestion determinada
     * @param {integer} id identificador del usuario
     * @param {string} gestion anio o gestion a buscar
     * @returns 
     */
    getListaDeclaracionesGestion: async (id, gestion) => {
        let response = null
        await API.get('/declaracion-jurada/obtener-declaraciones-gestion', { params: { id: id, gestion: gestion }, headers: authHeader() })
            .then((res) => { response = res })
            .catch((err) => { response = err.response ? err.response : {} })
        return response
    },
    
    /**
     * obtiene la informacion relacionada a una declaracion jurada
     * @param {integer} idDeclaracion identificardor de la declaracion jurada
     * @returns
     */
    getDeclaracion: async (idDeclaracion) => {
        let response = null
        await API.get('/declaracion-jurada/obtener-declaracion', { params: { idDeclaracion: idDeclaracion }, headers: authHeader() })
            .then((res) => { response = res })
            .catch((err) => { response = err.response ? err.response : {} })
        return response
    },

    /**
     * obtiene la informacion relacionada a la ultima declaracion jurada realizada por el usuario
     * @returns
     */
    getUltimaDeclaracion: async () => {
        let response = null
        await API.get('/declaracion-jurada/obtener-ultima-declaracion', { headers: authHeader() })
            .then((res) => { response = res })
            .catch((err) => { response = err.response ? err.response : {} })
        return response
    },

    /**
     * 
     * @param {integer} id identificacion de la declaracion jurada
     * @param {object} data {usuario, instituciones, categorias, fecha} objeto que representa los datos de la declaracion jurada
     * @returns 
     */
    editDeclaracion: async (id, data) => {
        let response = null
        await API.post('/declaracion-jurada/actualizar-declaracion', data, { params: { idDeclaracion: id }, headers: authHeader() })
            .then((res) => { response = res })
            .catch((err) => { response = err.response ? err.response : {} })
        return response
    },

    /**
     * obtinene todas las categorias existentes en a base de datos
     * @returns 
     */
    getCategorias: async () => {
        let response = null
        await API.get('/declaracion-jurada/obtener-categorias', { headers: authHeader() })
            .then((res) => { response = res })
            .catch((err) => { response = err.response ? err.response : {} })
        return response
    },

    /**
     * obtiene todas las instituciones registradas en la base de datos
     * @returns 
     */
    getInstituciones: async () => {
        let response = null
        await API.get('/declaracion-jurada/obtener-instituciones', { headers: authHeader() })
            .then((res) => { response = res })
            .catch((err) => { response = err.response ? err.response : {} })
        return response
    },

    /**
     * 
     * @param {integer} idUsuario idetificador dedl usuario
     * @param {object} declaracion objeto que contiene la informacion de la nueva declaracion jurada {informacion, instituciones, categorias}
     * @returns 
     */
    createDeclaracion: async (idUsuario, declaracion) => {
        let response = null
        await API.post('/declaracion-jurada/crear-declaracion-jurada', declaracion, { params: { id: idUsuario }, headers: authHeader() })
            .then((res) => { response = res })
            .catch((err) => { response = err.response ? err.response : {} })
        return response
    }, 

    /**
     * 
     * @param {integer} idDeclaracion identificacdor de la declaracion jurada
     * @returns 
     */
    deleteDeclaracion: async (idDeclaracion) => {
        let response = null
        await API.post('/declaracion-jurada/eliminar-declaracion', {}, { params: { idDeclaracion: idDeclaracion }, headers: authHeader() })
            .then((res) => { response = res })
            .catch((err) => { response = err.response ? err.response : {} })
        return response
    }, 

    /**
     * Servicios para obtener informacion de los usuarios
     * @param {integer} idUsuario identificar del usuario
     * @returns Object
     */
    InformacionUsuario: async (idUsuario) => {
        let response = null
        await API.get('/usuario/informacion-usuario', {params: { idUsuario: idUsuario }, headers: authHeader() })
            .then((res) => { response = res })
            .catch((err) => { response = err.response ? err.response : {} })
        return response
    },

    /**
     * Servicios para obtener un filtro de usuarios por su ci
     * @param {integer} ci numero con el cual se desea realizar el filtro
     * @returns
     */
    getListaUsuario: async (ci) => {
        let response = null
        await API.get('/usuario/obtener-lista-usuario', {params: { ci: ci }, headers: authHeader() })
            .then((res) => { response = res })
            .catch((err) => { response = err.response ? err.response : {} })
        return response
    },

    /**
     * Servicios para obtener informacion de un usuario
     * @param {integer} ci ci del usuario
     * @returns
     */
    getUsuario: async (ci) => {
        let response = null
        await API.get('/usuario/buscar-usuario', {params: { ci: ci }, headers: authHeader() })
            .then((res) => { response = res })
            .catch((err) => { response = err.response ? err.response : {} })
        return response
    }
}



export default DeclaracionJuradaServices