
import logoUmssBase64 from './logoUmssBase64';
import AuthService from "../services/authService";

export const formatoPDF = (fecha, usuario, categoria, categorias, instituciones = []) => {


    const rolUsuario = AuthService.getRolUsuario()
    let letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let auxInstitucion = []

    auxInstitucion = [...instituciones]
    let tipoR = auxInstitucion.filter(institucion => institucion.tipo === 'R').length
    for (let i = 1; i > tipoR; i--) {
        auxInstitucion.push(
            {
                nombre: "",
                cargo: "",
                total_ganado: null,
                tipo: "R"
            }
        )
    }
    let tipoI = auxInstitucion.filter(institucion => institucion.tipo === 'I').length
    for (let i = 2; i > tipoI; i--) {
        auxInstitucion.push(
            {
                nombre: "",
                cargo: "",
                total_ganado: null,
                tipo: "I"
            }
        )
    }


    /**
     * funcion que genera una lista de listas de objetos que representa cada fila de la tabla categoria
     * @returns array de items categorias
     */
    function generarTablaCategoria() {
        let listaObj = []
        listaObj.push([
            {
                border: [true, true, true, true],
                text: 'CATEGORÍA',
                bold: true,
            },
            {
                border: [true, true, true, true],
                text: 'REMUNERACIÓN (EN Bs.)',
                bold: true,
            },
        ])

        let count = 1;
        categorias.forEach(element => {
            let cat = categoria.filter(aux => aux.id_categoria === element.id_categoria)
            
            listaObj.push([
                {
                    border: [true, true, true, true],
                    text: count + ") " + element.nombre,
                },
                {
                    border: [true, true, true, true],
                    text: (Math.round(cat[0].remuneracion * 100) / 100).toFixed(2),
                    alignment: 'center'
                },
            ])
            count++;
        });

        listaObj.push([
            {
                border: [true, true, true, true],
                text: 'TOTAL',
                bold: true,
                alignment: 'right'
            },
            {
                border: [true, true, true, true],
                text: totalRemuneracionesCategoria(),
                alignment: 'center'
            },
        ])
        return listaObj;
    }

    function totalRemuneracionesCategoria() {
        let suma = 0;
        categoria.forEach(element => {
            suma += element.remuneracion
        });
        return (Math.round(suma * 100) / 100).toFixed(2)
    }

    function totalRemuneracionInstituciones() {
        let suma = 0;
        instituciones.forEach(element => {
            suma += parseFloat(element.total_ganado)
        });
        return (Math.round(suma * 100) / 100).toFixed(2)
    }

    return {
        watermark: rolUsuario === 'Administrador'? { text: 'PERSONAL ACADÉMICO', color: 'blue', opacity: 0.15} : null,
        
        content: [
            {
                style: 'tableExample',

                table: {
                    widths: ['auto', '*'],
                    body: [
                        [
                            {
                                rowSpan: 4,
                                image: 'data:image/png;base64,' + logoUmssBase64,
                                width: 80
                            },
                            { text: 'FORM-DCA-FORM-08', style: 'header', }
                        ],
                        [
                            '',
                            { text: 'UNIVERSIDAD MAYOR DE SAN SIMÓN', style: 'header' },
                        ],
                        [
                            '',
                            { text: 'DIRECCIÓN DE PLANIFICACIÓN ACADÉMICA', style: 'headerdos' }
                        ],
                        [
                            '',
                            { text: 'DEPARTAMENTO DE PERSONAL ACADÉMIC0', style: 'headerdos' },
                        ],
                    ]
                },
                layout: {
                    defaultBorder: false,
                }
            },

            /**
             * Tabla de datos del usuario
             **/

            {
                style: 'tableExample',

                table: {
                    widths: ['auto', 'auto', '*', 'auto', 'auto'],
                    body: [
                        [
                            {
                                colSpan: 3,
                                border: [false],
                                text: ""
                            },
                            '',
                            '',
                            {
                                border: [false],
                                bold: true,
                                alignment: 'right',
                                text: 'Fecha de llenado:'
                            },
                            {
                                border: [true, true, true, true],
                                alignment: 'center',
                                text: fecha.fecha
                            }
                        ],
                        [
                            {
                                border: [false, false, false, false],
                                bold: true,
                                alignment: 'right',
                                text: 'Yo:'
                            },
                            {
                                border: [true, true, false, true],
                                text: ""
                            },
                            {
                                colSpan: 2,
                                border: [false, true, false, true],
                                alignment: 'center',
                                text: `${(usuario.nombre1 + " " + usuario.nombre2 + " " + usuario.paterno + " " + usuario.materno)}`
                            },
                            '',
                            {
                                border: [false, true, true, true],
                                text: ''
                            },
                        ],
                        [
                            {
                                border: [false, false, false, false],
                                bold: true,
                                alignment: 'right',
                                text: 'con CI:'
                            },
                            {
                                border: [true, true, true, true],
                                alignment: 'center',
                                text: usuario.ci
                            },
                            {
                                border: [false, false, false, false],
                                text: ''
                            },
                            '',
                            ''
                        ],
                    ]
                },
                layout: {
                    defaultBorder: false,
                }
            },
            {
                style: 'tableExample',
                bold: true,
                table: {
                    widths: ['auto', '*', 'auto', 'auto'],
                    body: [
                        [
                            {
                                border: [false, false, false, false],
                                text: 'DECLARO PERCIBIR EN EL MES DE:'
                            },
                            {
                                border: [false, false, false, false],
                                alignment: 'center',
                                text: fecha.mes
                            },
                            {
                                border: [false, false, false, false],
                                text: 'DE LA GESTIÓN:'
                            },
                            {
                                border: [true, true, true, true],
                                text: fecha.gestion
                            },
                        ],
                    ]
                },
                layout: {
                    defaultBorder: false,
                }
            },
            {
                style: 'tableExample',
                bold: true,
                table: {
                    widths: ['auto', '*'],
                    body: [
                        [
                            {
                                border: [false, false, false, false],
                                text: 'Motivo de declaración:'
                            },
                            {   
                                border: [true, true, true, true],                            
                                alignment: 'center',
                                text: fecha.motivo
                            },
                        ],
                    ]
                },
                layout: {
                    defaultBorder: false,
                }
            },
            {
                text: 'LAS SIGUIENTES REMUNERACIONES EN EL SECTOR PÚBLICO:',
                style: 'subheader',
                alignment: 'center'
            },
            /**
             * Tabla a formulario de categorias
             */
            {
                text: 'A.- UNIVERSIDAD MAYOR DE SAN SIMÓN',
                style: 'subheader',
                alignment: 'left'
            },
            {
                style: 'tableExample',
                table: {
                    widths: ['*', 'auto'],
                    body: generarTablaCategoria()
                },
                layout: {
                    defaultBorder: false,
                }
            },
            /**
             * nota de la tabla a
             */
            {
                text: 'IMPORTANTE: En el caso de LOS ITEMS 1 y 2 (docentes y administrativos) se considera el total ganado mensual independientemente del tipo de contrato (indefinido, plazo fijo o servicios). En el caso de los servicios comprendidos del ITEM 3 al ITEM 9 se considera la remuneración de las fechas que se ha efectuado el servicio sin considerar si este ya ha sido pagado en efectivo o no. No debe incluirse el servicio para el cual se firma la presente declaración.',
                style: 'paragraf'
            },


            /**
             * tabla de las instituciones publicas
             */

            auxInstitucion.filter(institucion => institucion.tipo === 'I').map((item, index) => {
                letras = letras.slice(1);
                return(
                    {
                        style: 'tableExample',
                        table: {
                            widths: ['auto', 50, '*', 'auto', 50],
                            body: [
                                [
                                    {
                                        colSpan: 5,
                                        border: [false, false, false, false],
                                        text: `${letras[0]}.- OTRA INSTITUCIÓN PÚBLICA`,
                                        style: 'subheader',
                                        alignment: 'left'
                                    },
                                    '',
                                    '',
                                    '',
                                    ''
                                ],
                                [
                                    {
                                        border: [true, true, false, false],
                                        text: 'Nombre de la institución pública:'
                                    },
                                    {
                                        border: [false, true, true, false],
                                        text: ''
                                    },
                                    {
                                        colSpan: 3,
                                        border: [true, true, true, true],
                                        text: item.nombre
                                    },
                                    '',
                                    ''
                                ],
                                [
                                    {
                                        border: [true, false, false, true],
                                        text: ''
                                    },
                                    {
                                        border: [false, false, true, true],
                                        text: 'Cargo: '
                                    },
                                    {
                                        border: [true, true, true, true],
                                        text: item.cargo
                                    },
                                    {
                                        border: [true, true, true, true],
                                        text: 'Total Ganado (Bs.):'
                                    },
                                    {
                                        border: [true, true, true, true],
                                        text: (Math.round(item.total_ganado * 100) / 100).toFixed(2),
                                        alignment: 'right'
                                    },
                                ],
                            ]
                        },
                        layout: {
                            defaultBorder: false,
                        }                    
                    }
            )}),

            /**
             * Tabla de renta de jubilaciones
             */
            auxInstitucion.filter(institucion => institucion.tipo === 'R').map((institucion, index) => {
                letras = letras.slice(1);               
                return(
                    {
                        style: 'tableExample',
                        table: {
                            widths: ['auto', 50, '*', 'auto', 50],
                            body: [
                                [
                                    {
                                        colSpan: 5,
                                        border: [false, false, false, false],
                                        text: `${letras[0]}.- RENTA DE JUBILACIÓN`,
                                        style: 'subheader',
                                        alignment: 'left'
                                    },
                                    '',
                                    '',
                                    '',
                                    ''
                                ],
                                [
                                    {
                                        border: [true, true, false, false],
                                        text: 'Nombre de la institución pública:'
                                    },
                                    {
                                        border: [false, true, true, false],
                                        text: ''
                                    },
                                    {
                                        colSpan: 3,
                                        border: [true, true, true, true],
                                        text: institucion.nombre
                                    },
                                    '',
                                    ''
                                ],
                                [
                                    {
                                        border: [true, false, false, true],
                                        text: ''
                                    },
                                    {
                                        border: [false, false, true, true],
                                        text: 'Cargo: '
                                    },
                                    {
                                        border: [true, true, true, true],
                                        text: institucion.cargo
                                    },
                                    {
                                        border: [true, true, true, true],
                                        text: 'Total (Bs.):',
                                        alignment: 'right'
                                    },
                                    {
                                        border: [true, true, true, true],
                                        text: (Math.round(institucion.total_ganado * 100) / 100).toFixed(2),
                                        alignment: 'right'
                                    },
                                ],

                            ]
                        },
                        layout: {
                            defaultBorder: false,
                        }
                    }
            )}),
            /**
             * tabla sumatoria del total ganado en instituciones publicas
             */
            {
                style: 'tableExample',
                table: {
                    widths: ['*', '*', '*', '*', '*', 50],
                    heights: ['auto', 'auto'],
                    body: [
                        [
                            {
                                colSpan: 3,
                                border: [true, true, false, false],
                                text: 'TOTAL GANADO EN INSTITUCIONES PÚBLICAS'
                            },
                            '',
                            '',
                            {
                                colSpan: 3,
                                border: [false, true, true, false],
                                text: ''
                            },
                            '',
                            ''
                        ],
                        [
                            {
                                colSpan: 2,
                                border: [true, false, false, true],
                                text: ''
                            },
                            '',
                            {
                                colSpan: 3,
                                border: [false, false, false, true],
                                text: 'TOTAL REMUNERACIONES DEL SECTOR PÚBLICO:'
                            },
                            '',
                            '',
                            {
                                border: [true, true, true, true],
                                text: totalRemuneracionInstituciones(),
                                alignment: 'right'
                            },
                        ],

                    ]
                },
                layout: {
                    defaultBorder: false,
                }
            },
            /**
             * nota de la tabla d
             */
            {
                margin: [0, 0, 0, 10],
                text: 'NOTA.- El monto declarado corresponde al total ganado (no al líquido pagable), es decir al monto percibido antes de deduciones, aportes laborales e impuestos. Se considera institución pública a toda aquella que se encuentra comprendida en los Artículos 3, 4 y 5 de la Ley 1178 del 20 de Julio de 1990.',
                style: 'paragraf',
            },
            /**
             * Tabla firma del docente
             */
            {
                style: 'tableExample',
                table: {
                    widths: ['*', '*', '*', 'auto', 'auto'],
                    heights: [30],
                    body: [
                        [
                            {
                                border: [true, true, true, true],
                                text: 'FIRMA: '
                            },
                            {
                                colSpan: 2,
                                border: [true, true, true, true],
                                text: ''
                            },
                            '',
                            {
                                border: [true, true, true, true],
                                text: 'ACLARACIÓN DE LA FIRMA:'
                            },
                            {
                                border: [true, true, true, true],
                                text: `${usuario.nombre1} ${usuario.nombre2}  ${usuario.paterno}  ${usuario.materno}`
                            }
                        ],                        
                    ]
                },
                layout: {
                    defaultBorder: false,
                }
            },
            {
                style: 'tableExample',
                table: {
                    widths: ['*', '*', '*', '*'],
                    heights: ['auto'],
                    body: [                        
                        [
                            {
                                border: [true, true, true, true],
                                text: '# CI o Pasaporte: '
                            },
                            {
                                border: [true, true, true, true],
                                text: usuario.ci
                            },                            
                            {
                                border: [true, true, true, true],
                                text: 'Expedido en:'
                            },
                            {
                                border: [true, true, true, true],
                                text: fecha.lugar_emision_ci
                            }
                        ]
                    ]
                },
                layout: {
                    defaultBorder: false,
                }
            },
            {
                text: 'Declaro bajo juramento que los datos arriba consignados son fidedignos y corresponden fielmente a la verdad, en consecuencia asumo responsabilidad de lo declarado.',
                style: 'paragraf',
                bold: true
            }
        ],
        styles: {
            header: {
                fontSize: 12,
                bold: true,
                alignment: 'center',
                margin: [0, 0, 0, 0]
            },
            headerdos: {
                fontSize: 12,
                alignment: 'center',
                margin: [0, 0, 0, 0]
            },
            subheader: {
                font: 'Roboto',
                fontSize: 9,
                bold: true,
                margin: [0, 10, 0, 0]
            },
            paragraf: {
                font: 'Roboto',
                fontSize: 9,
            },
            tableExample: {
                margin: [0, 0, 0, 0],
                font: 'Roboto',
                fontSize: 9
            },
        },
        defaultStyle: {
            alignment: 'justify',
        }

    }
}