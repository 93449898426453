
import { useRoutes, Navigate } from "react-router-dom";
import Login from "./components/Login";
import Formulario from "./components/Formulario";
import AuthService from "./services/authService";
import LayoutPage from "./pages/LayoutPage";
import Home from "./pages/Home";
import DocentePrincipal from "./pages/DocentePrincipal";
import { construct } from "./RBAC";
import Administrador from "./pages/Administrador";
import LoginWebsis from "./pages/LoginWebsis";

/**
 * Crea la estructura de rutas y componentes del sistema
 * @returns Routes
 */
function Router() {
	let token = AuthService.getToken()
	let isAuth = token ? true : false;
	const rol = AuthService.getRolUsuario() || ''
    return useRoutes([
		//rutas publicas
		{
			path: '/',
			element: <LayoutPage/>,
			children: [
				{
					path: '/',
					element: isAuth ? 
					rol === 'Docente' ? (
						<Navigate to="/declaraciones" replace/>
					) : ( <Navigate to="/declaracion-administracion" replace/> ) : (
						<Navigate to="/index" />
					),
				},
				{
					path: 'index',
					element: !isAuth ? <Home /> : <Navigate to="/" replace />,
				},
				{
					path: 'login',
					element: !isAuth ? <Login /> : <Navigate to="/" replace />,
				},
				{
					path: 'declaraciones',
					element: (isAuth && rol === 'Docente') ? <DocentePrincipal/> : <Navigate to="/" replace />,
				},
				{
					path: 'formulario/:idDeclaracion',
					element: (isAuth && rol === 'Docente') ?  <Formulario/> : <Navigate to="/" replace />
				},
				{
					path: 'declaracion-administracion',
					element: (isAuth && rol === 'Administrador') ? <Administrador/> : <Navigate to="/" replace />
				},
				{
					path: 'login-websis',
					element: <LoginWebsis/>,
				}
			],
		},
		
	]);
}

export default Router