import { Outlet } from "react-router-dom";
import Navbar from "../components/navbar";
import Footer from "../components/Footer";

function LayoutPage(){
    return(
        <>
            <Navbar/>
            <div style={{minHeight:'100vh', display:"flex", justifyContent: 'center', position: 'relative',}}>
                <Outlet/>
            </div>
            <Footer/>
        </>
    )
}

export default LayoutPage;