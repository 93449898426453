import { useState, useEffect } from "react";
import { Button, Col, Collapse, Container, Form, InputGroup, Row } from "react-bootstrap";
import { CaretRightFill, CaretDownFill, Search } from "react-bootstrap-icons"
import declaracionJuradaServices from "../services/declaracionJuradaServices"
import ExpandoRow from "../components/ExpandRow";
import TarjetaUsuario from "../components/TarjetaUsuario";
import '../styles/collapsableList.css'
import { ToastError } from "../components/Alerts";

function Administrador() {

    const [usuarioCI, setUsuarioCI] = useState('')
    const [idUsuario, setIdUsuario] = useState()
    const [listaGestiones, setListaGestiones] = useState([])
    const [mensaje, setMensaje] = useState('')
    const [visible, setVisible] = useState(false)
    const [usuario, setUsuario] = useState()
    const [showAll, setShowAll] = useState(false);
    let [count, setCount] = useState(0);
    const [displayGestiones, setDisplayGestiones] = useState(new Map());

    const cargarGestiones = async (idUsuario) => {
        const response = await declaracionJuradaServices.getListaGestiones(idUsuario, 1)
        if (response.data.success === true) {
            setListaGestiones(response.data.data)
            if (response.data.data.length > 0) {
                setMensaje('')
            } else {
                setMensaje('No se encontro registro de Declaraciones juradas')
            }
        } else {
            setListaGestiones([])
            setMensaje('')
            console.log('error', response)
        }
    }
   
    function handleShowOne(index){
        let aux = new Map(displayGestiones);
        if(aux.get(index)){
            aux.set(index, false);
        } else {
            aux.set(index, true);
        }
        setDisplayGestiones(aux);
    }

    function handleShowAll() {
        let value = false;
        let aux = new Map(displayGestiones);
        if(showAll) value = true;
        listaGestiones.forEach((gestion, key) => {
            aux.set(key, value);
        })
        setDisplayGestiones(aux);
    }


    const onChange = (e) => {
        setUsuarioCI(e.target.value)
    }    

    const handleSubmit = async () => {
        let response = await declaracionJuradaServices.getUsuario(usuarioCI)
        if (response.data.success === true) {
            setUsuario(response.data.data)
            setIdUsuario(response.data.data.id_usuario)
            cargarGestiones(response.data.data.id_usuario)
            setVisible(true)
        } else {
            setListaGestiones([])
            setMensaje('')
            setVisible(false)
            ToastError.fire({ title: response.data.message });            
            console.log('error', response)
        }
    }

    useEffect(() => {
        if(count > 0) handleSubmit()
    }, [count])

    return (
        <Container style={{ width: '50em' }}>
            <br />
                <Form.Label column sm={5}>
                    Cedula de identidad:
                </Form.Label>
                <Col>
                    <InputGroup>
                        <Form.Control type="text" onChange={onChange} />
                        <Button variant="outline-secondary" onClick={()=>setCount(prevCount => prevCount + 1)}>
                            <Search />
                        </Button>
                    </InputGroup>
                </Col>
            <br />
            <Collapse in={visible}>
                <div>
                    {usuario ?
                        <TarjetaUsuario nombre1={usuario.nombre1} nombre2={usuario.nombre2} paterno={usuario.paterno} materno={usuario.materno} email={usuario.email} ci={usuario.ci}></TarjetaUsuario>
                        : null
                    }
                </div>
            </Collapse>
            <br />
            <div className="items">
                <div className="item-header">
                    <Button onClick={()=>{setShowAll(!showAll); handleShowAll()}} >
                        
                        { (!showAll)? (<CaretDownFill/>):(<CaretRightFill />) }
                            
                    </Button>
                    <span>Gestiones</span>
                </div>
                <div>
                    {listaGestiones.map((item, index) => (
                        <div key={index}>
                            <div className="item-header">
                                <Button onClick={()=>{handleShowOne(index)}}>
                                    { displayGestiones.get(index) ? (<CaretDownFill/>):(<CaretRightFill />) }
                                </Button>
                                <span>{item.gestion}</span> 
                            </div>
                            <ExpandoRow idUsuario={idUsuario} gestion={item.gestion} className={ displayGestiones.get(index) ? '' : 'hidden'}/>
                        </div>
                    ))}
                </div>
            </div>
            <label style={{ color: 'red' }}>{mensaje}</label>
        </Container>
    )
}

export default Administrador;